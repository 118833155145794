import React from 'react';

const NotFoundPage: React.FC = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>This page does not exist.</p>
  </div>
);

export default NotFoundPage;
